import {apiSlice} from "redux/api/baseApiSlice";
import {NegotiationLps, NegotiationLpsPatch, NegotiationLpsPost} from "./negotiationLps";
import {Country} from "../../types/countries";

export const negotiationLpsApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getLpsCountries: builder.query<Country[], void>({
            query: () => {
                return {
                    url: '/negotiation/lps-countries/',
                };
            },
        }),
        getNegotiationLps: builder.query<NegotiationLps[], { negotiationUuid: string }>({
            query: ({negotiationUuid}) => {
                return {
                    url: `/negotiation/negotiations/${negotiationUuid}/lps/`,
                };
            },
            providesTags: (result, error, arg, meta) => [{type: "NegotiationLps", id: arg.negotiationUuid}, 'Negotiation']
        }),
        addNegotiationLps: builder.mutation<NegotiationLps, NegotiationLpsPost>({
            query: ({negotiation, ...data}) => {
                return {
                    url: `/negotiation/negotiations/${negotiation}/lps/`,
                    method: 'POST',
                    body: data
                }
            },
            invalidatesTags: (result, error, arg, meta) => [{type: "NegotiationLps", id: arg.negotiation}]
        }),
        removeNegotiationLps: builder.mutation<void, { negotiation_id: string, lps_id: string }>({
            query: ({negotiation_id, lps_id}) => {
                return {
                    url: `/negotiation/negotiations/${negotiation_id}/lps/${lps_id}/`,
                    method: 'DELETE',
                }
            },
            invalidatesTags: (result, error, arg, meta) => [{type: "NegotiationLps", id: arg.negotiation_id}]

        }),
        updateNegotiationLps: builder.mutation<NegotiationLps, NegotiationLpsPatch>({
            query: ({negotiation, uuid, ...body}) => {
                return {
                    url: `/negotiation/negotiations/${negotiation}/lps/${uuid}/`,
                    method: 'PATCH',
                    body
                }
            },
            invalidatesTags: (result, error, arg, meta) => [{type: "NegotiationLps", id: arg.negotiation}]
        }),
    })
})

export const {
    useGetLpsCountriesQuery,
    useRemoveNegotiationLpsMutation,
    useAddNegotiationLpsMutation,
    useUpdateNegotiationLpsMutation,
    useGetNegotiationLpsQuery,
} = negotiationLpsApiSlice