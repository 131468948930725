import {Form, Select} from "antd";
import React from "react";
import {AntCurrencyFormatInput} from "../../../inputNumber";
import {useGetLpsCountriesQuery} from "../../../../redux/api/negotiationLpsApiSlice";

export function CountryFormItem({excludedCountriesCodes}: { excludedCountriesCodes?: string[] }) {

    const {data: countries, isLoading: isLoadingCountries} = useGetLpsCountriesQuery()
    const countriesOptions = ([...(countries ?? [])])
        .sort((a, b) => a.label.localeCompare(b.label))
        .map(c => ({
            label: c.label,
            value: c.code,
            disabled: excludedCountriesCodes?.includes(c.code)
        }))

    return <Form.Item name={"country"} label={'Paese'} rules={[{required: true, message: "Campo obbligatorio"}]}>
        <Select
            labelInValue={true}
            optionFilterProp={'label'}
            showSearch
            options={countriesOptions}
            loading={isLoadingCountries}
            virtual={false}
        />
    </Form.Item>
}

export function NetPremiumFormItem() {

    return <Form.Item
        label={"Premio Imponibile 100%"}
        name={'net_premium'}
    >
        <AntCurrencyFormatInput/>
    </Form.Item>
}

export function TivFormItem() {

    return <Form.Item
        label={"TIV"}
        name={'tiv'}
    >
        <AntCurrencyFormatInput/>
    </Form.Item>
}

export default function NegotiationLpsFormItems({withTiv, excludedCountriesCodes}: {
    withTiv?: boolean,
    excludedCountriesCodes?: string[]
}) {

    return <>
        <CountryFormItem excludedCountriesCodes={excludedCountriesCodes}/>
        <NetPremiumFormItem/>
        {withTiv && <TivFormItem/>}
    </>
}