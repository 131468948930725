import {apiSlice} from "redux/api/baseApiSlice";
import {
    CreateNegotiationTransitionRequest,
    CreateNewNegotiationRequest,
    DuplicateNegotiation,
    NegotiationModel,
    NegotiationTransitionHistory,
    NegotiationTransitionModel,
    NegotiationUserModel,
    UpdateNegotiationRequest
} from "types/negotiations";
import {TableFilter, TablePagination, TableSorter} from "../../types";
import {GetNegotiationReferred} from "../../types/authorities";
import handleDownloadFile from "../../utils/handleDownloadFile";

export type QueryNegotiationsParam = {
    pagination?: TablePagination
    filters?: TableFilter[]
    sorters?: TableSorter[]
}

export type DownloadNegotiationsParam = {
    filters?: {
        key: string,
        range: number[] | string[]
    }[]
    sorters?: TableSorter[]
    all_fields?: boolean
}


export const negotiationsApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => {
        return ({
            createNegotiation: builder.mutation<NegotiationModel, CreateNewNegotiationRequest>({
                query: (data) => ({
                    url: `/negotiation/negotiations/`,
                    method: 'POST',
                    body: {...data}

                }),
            }),
            checkDuplicatesNegotiation: builder.mutation<DuplicateNegotiation[], {
                contractor: string,
                lob: string,
                uw_year: number
            }>({
                query: (data) => ({
                    url: `/negotiation/negotiations/check_duplicates/`,
                    method: 'GET',
                    params: data
                })
            }),
            updateNegotiation: builder.mutation<NegotiationModel, {
                id: string,
                data: Partial<UpdateNegotiationRequest>
            }>({
                query: ({id, data}) => ({
                    url: `/negotiation/negotiations/${id}/`,
                    method: 'PATCH',
                    body: {...data}
                }),
                invalidatesTags: (result, error, arg) => [
                    ...("fund" in arg.data || "quoted_premium" in arg.data || "contractor" in arg.data) ?  [{type: 'AgreementRows' as const, id: arg.id}] : [],
                    'CoverageConfirmation',
                    'Negotiation'
                ]
            }),
            getNegotiationDetail: builder.query<NegotiationModel, string>({
                query: (id) => `/negotiation/negotiations/${id}/`,
                // keepUnusedDataFor: 5,
                //cache for 5s
                providesTags: ['Negotiation', 'NegotiationToAlignWithinCoverageConfirmation'],
            }),
            getNegotiations: builder.query<{
                count: number,
                premiums: number | null,
                results: NegotiationModel[]
            },
                QueryNegotiationsParam | void>({
                query: (params: QueryNegotiationsParam | void) => {
                    let url = '/negotiation/negotiations/'
                    if (params) {
                        let options: string[] = []
                        if (params.pagination) {
                            if (params.pagination && params.pagination.pageSize) {
                                options.push(`page_size=${params.pagination.pageSize}`)
                            }
                            if (params.pagination && params.pagination.page) {
                                options.push(`page=${params.pagination.page}`)
                            }
                        }
                        if (params.filters) {
                            for (let i = 0; i < params.filters.length; i++) {
                                if (params.filters[i].range.length) {
                                    switch (params.filters[i].key) {
                                        case ('reception_date'):
                                        case ('prevailing_expiring_date'):
                                        case ('policy_effective_date'):
                                        case ('policy_expiring_date'):
                                            options.push(`${params.filters[i].key}_from=${params.filters[i].range[0]} 00:00:00`)
                                            options.push(`${params.filters[i].key}_to=${params.filters[i].range[1]} 23:59:59`)
                                            break
                                        case ('uw_year'):
                                            options.push(`${params.filters[i].key}_from=${params.filters[i].range[0]}`)
                                            options.push(`${params.filters[i].key}_to=${params.filters[i].range[1]}`)
                                            break
                                        case ('isa_quoted_premium_share'):
                                        case ('policy_insured_sum'):
                                        case ('attachment_point'):
                                        case ('days_to_dead_line'):
                                        case ('days_to_expiration'):
                                        case ('isa_fee'):
                                        case ('net_premium_to_reinsurers'):
                                        case ('saved_premium'):
                                        case ('relationship_quality'):
                                            options.push(`${params.filters[i].key}_min=${params.filters[i].range[0]}`)
                                            options.push(`${params.filters[i].key}_max=${params.filters[i].range[1]}`)
                                            break
                                        case ('reinsured_share_perc'):
                                        case ('reinsurer_broker_fee_perc'):
                                        case ('isa_share_perc'):
                                        case ('fee_bank_perc'):
                                            const min = Number(params.filters[i].range[0])
                                            const max = Number(params.filters[i].range[1])
                                            options.push(`${params.filters[i].key}_min=${min * 0.01}`)
                                            options.push(`${params.filters[i].key}_max=${max * 0.01}`)
                                            break
                                        default:
                                            options.push(`${params.filters[i].key}=${params.filters[i].range}`)
                                    }
                                }
                            }
                        }


                        if (params.sorters && params.sorters.length > 0) {

                            const sorterParam = 'ordering=' + params.sorters.map(el => {
                                const key = el.key === 'days_to_dead_line' ? 'days_to_deadline' : el.key === 'days_to_expiration' ? 'days_to_expire' : el.key
                                if (el.type === 'ascend') {
                                    return key
                                } else if (el.type === 'descend') {
                                    return `-${key}`
                                }
                            }).filter(el => el).join(',')

                            options.push(sorterParam)
                        }

                        if (options.length > 0) {
                            url += '?'
                            for (let i = 0; i < options.length; i++) {
                                url += options[i]
                                if (i < options.length - 1) {
                                    url += '&'
                                }
                            }
                        }
                    }
                    return url
                },
                transformResponse: (response: {
                    results: NegotiationModel[],
                    premiums: number | null,
                    count: number
                }, meta, args) => {
                    return {
                        count: response.count,
                        premiums: response.premiums,
                        results: response.results
                    }
                },
                // keepUnusedDataFor: 5,
                //cache for 5s
            }),
            createNegotiationStatusTransition: builder.mutation<NegotiationTransitionModel, {
                id: string,
                data: CreateNegotiationTransitionRequest
            }>({
                query: ({id, data}) => ({
                    url: `/negotiation/negotiations/${id}/transitions/`,
                    method: 'POST',
                    body: {...data}
                }),
                invalidatesTags: ['StateTransitions', 'CoverageConfirmation', 'Negotiation'],
            }),
            getNegotiationStatusTransitions: builder.query<NegotiationTransitionHistory[], string>({
                query: (id) => `/negotiation/negotiations/${id}/transitions/`,
                providesTags: ['StateTransitions'],
                // keepUnusedDataFor: 5,
                //cache for 5s
            }),
            getNegotiationReferringUsers: builder.query<NegotiationUserModel[], string>({
                query: (id) => `/negotiation/negotiations/${id}/referring_users/`,
                // keepUnusedDataFor: 5,
                //cache for 5s
            }),
            getNegotiationReferred: builder.query<GetNegotiationReferred, { page?: number, page_size?: number }>({
                query: (args) => ({
                    url: `negotiation/negotiations/referred/`,
                    params: args
                })
                // keepUnusedDataFor: 5,
                //cache for 5s
            }),
            getDownloadNegotiations: builder.mutation <{
                error: boolean,
                message: string
            }, QueryNegotiationsParam | void>({
                query: (params: DownloadNegotiationsParam | void) => {
                    let url = '/negotiation/negotiations/download'
                    if (params) {
                        let options: string[] = []

                        if (typeof params.all_fields === 'boolean') {
                            options.push(`all_fields=${params.all_fields}`)
                        }

                        if (params.filters) {
                            for (let i = 0; i < params.filters.length; i++) {
                                if (params.filters[i].range.length) {
                                    switch (params.filters[i].key) {
                                        case ('reception_date'):
                                        case ('prevailing_expiring_date'):
                                        case ('policy_effective_date'):
                                        case ('policy_expiring_date'):
                                            options.push(`${params.filters[i].key}_from=${params.filters[i].range[0]} 00:00:00`)
                                            options.push(`${params.filters[i].key}_to=${params.filters[i].range[1]} 23:59:59`)
                                            break
                                        case ('uw_year'):
                                            options.push(`${params.filters[i].key}_from=${params.filters[i].range[0]}`)
                                            options.push(`${params.filters[i].key}_to=${params.filters[i].range[1]}`)
                                            break
                                        case ('isa_quoted_premium_share'):
                                        case ('policy_insured_sum'):
                                        case ('attachment_point'):
                                        case ('days_to_dead_line'):
                                        case ('days_to_expiration'):
                                        case ('isa_fee'):
                                        case ('net_premium_to_reinsurers'):
                                        case ('saved_premium'):
                                            options.push(`${params.filters[i].key}_min=${params.filters[i].range[0]}`)
                                            options.push(`${params.filters[i].key}_max=${params.filters[i].range[1]}`)
                                            break
                                        case ('reinsured_share_perc'):
                                        case ('reinsurer_broker_fee_perc'):
                                        case ('isa_share_perc'):
                                            const min = Number(params.filters[i].range[0])
                                            const max = Number(params.filters[i].range[1])
                                            options.push(`${params.filters[i].key}_min=${min * 0.01}`)
                                            options.push(`${params.filters[i].key}_max=${max * 0.01}`)
                                            break
                                        default:
                                            options.push(`${params.filters[i].key}=${params.filters[i].range}`)
                                    }
                                }
                            }
                        }

                        if (params.sorters && params.sorters.length > 0) {

                            const sorterParam = 'ordering=' + params.sorters.map(el => {
                                const key = el.key === 'days_to_dead_line' ? 'days_to_deadline' : el.key === 'days_to_expiration' ? 'days_to_expire' : el.key
                                if (el.type === 'ascend') {
                                    return key
                                } else if (el.type === 'descend') {
                                    return `-${key}`
                                }
                            }).filter(el => el).join(',')

                            options.push(sorterParam)
                        }

                        if (options.length > 0) {
                            url += '?'
                            for (let i = 0; i < options.length; i++) {
                                url += options[i]
                                if (i < options.length - 1) {
                                    url += '&'
                                }
                            }
                        }
                    }

                    return {
                        url: url,
                        //method: 'GET',
                        //headers: {'Content-Type': 'application/csv'},
                        responseHandler: (response) => handleDownloadFile(response),
                        cache: "no-cache",
                    }
                },
            }),
            renewNegotiation: builder.mutation<{ new_negotiation_uuid: string | null }, {
                uuid: string,
                data: { renew: boolean, modify: boolean | null }
            }>({
                query: ({uuid, data}) => ({
                    url: `/negotiation/negotiations/${uuid}/renew/`,
                    method: 'POST',
                    body: {...data}
                }),
            }),
            archiveNegotiation: builder.mutation<void, { uuid: string }>({
                query: ({uuid}) => ({
                    url: `/negotiation/negotiations/${uuid}/archive/`,
                    method: 'POST',
                }),
                invalidatesTags: (result, error, arg) => [{
                    type: 'AgreementRows' as const,
                    id: arg.uuid
                }]
            }),
            cloneNegotiation: builder.mutation<{negotiation: string}, { uuid: string, lob: string }>({
                query: ({uuid, ...body}) => ({
                    url: `/negotiation/negotiations/${uuid}/clone/`,
                    method: 'POST',
                    body
                })
            }),
            extendNegotiation: builder.mutation<{ new_negotiation_uuid: string }, { uuid: string }>({
                query: ({uuid}) => ({
                    url: `/negotiation/negotiations/${uuid}/extend/`,
                    method: 'POST',
                }),
            }),
            assignPortfolioUserToNegotiation: builder.mutation<NegotiationModel, {
                negotiation_uuid: string,
                user_uuid: string
            }>({
                query: ({negotiation_uuid, user_uuid}) => ({
                    url: `/negotiation/negotiations/${negotiation_uuid}/assign_portfolio_user/`,
                    method: 'PATCH',
                    body: {user_uuid}
                }),
            }),
        });
    },
})

export const {
    useCreateNegotiationMutation,
    useCheckDuplicatesNegotiationMutation,
    useUpdateNegotiationMutation,
    useLazyGetNegotiationDetailQuery,
    useGetNegotiationDetailQuery,
    useLazyGetNegotiationsQuery,
    useLazyGetNegotiationStatusTransitionsQuery,
    useGetNegotiationStatusTransitionsQuery,
    useCreateNegotiationStatusTransitionMutation,
    useGetDownloadNegotiationsMutation,
    useRenewNegotiationMutation,
    useArchiveNegotiationMutation,
    useGetNegotiationReferringUsersQuery,
    useLazyGetNegotiationReferredQuery,
    useExtendNegotiationMutation,
    useAssignPortfolioUserToNegotiationMutation,
    useCloneNegotiationMutation,
} = negotiationsApiSlice