import {Button, message, Popconfirm} from "antd";
import {DeleteOutlined} from "@ant-design/icons";
import {useRemoveNegotiationLpsMutation} from "../../../../redux/api/negotiationLpsApiSlice";
import {useCallback} from "react";

export default function NegotiationLpsDelete({negotiationUuid, lpsUuid}: { negotiationUuid: string, lpsUuid: string }) {

    const [remove, {isLoading}] = useRemoveNegotiationLpsMutation()
    const handleRemove = useCallback(async() => {
        try {
            if (negotiationUuid) {
                await remove({negotiation_id: negotiationUuid, lps_id: lpsUuid}).unwrap()
                message.success("Paese LPS rimosso con successo")
            }
        } catch {
            message.error("Errore nella rimozione del paese LPS")
        }
    }, [lpsUuid, negotiationUuid, remove])

    return <Popconfirm
        title="Eliminare il paese LPS?"
        okText="Si"
        cancelText="No"
        onConfirm={handleRemove}
    >
        <Button
            icon={<DeleteOutlined/>}
            disabled={isLoading}
            type='ghost'
        />
    </Popconfirm>
}