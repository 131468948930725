import {Menu, MenuProps} from "antd";
import {
    DeleteOutlined,
    DownloadOutlined,
    EditOutlined,
    FileOutlined,
    FolderOutlined,
    InfoCircleOutlined
} from "@ant-design/icons";
import {message} from "antd";
import {useState} from "react";
import RemoveDocumentModal from "./RemoveDocumentModal";
import RenameDocumentModal from "./RenameDocumentModal";
import MoveDocumentModal from "./MoveDocumentModal";
import PropertiesDocumentModal from "./PropertiesDocumentModal";
import {useLazyGetDocumentUrlQuery} from "../../redux/api/documentsApiSlice";
import useFileDownloader from "../../hooks/useFileDownloader";

export default function DropDownMenu(props: any) {
    const [getUrl] = useLazyGetDocumentUrlQuery()
    const {downloadFile} = useFileDownloader()
    const [isModalRemoveVisible, setIsModalRemoveVisible] = useState<boolean>(false)
    const [isModalRenameVisible, setIsModalRenameVisible] = useState<boolean>(false)
    const [isModalMoveVisible, setIsModalMoveVisible] = useState<boolean>(false)
    const [isModalPropertiesVisible, setIsModalPropertiesVisible] = useState<boolean>(false)

    const handleMenuClick: MenuProps['onClick'] = e => {
        switch (e.key) {
            case('download'):
                for (let i in props.selectedDocument)
                    getUrl({negotiation_uuid: props.negotiation, uuid: props.selectedDocument[i].key}).unwrap()
                        .then((response) => {
                            downloadFile(response.url)
                        })
                        .catch((e: any) => {
                            message.error("Impossibile scaricare il file " + props.selectedDocument[i].title)
                            console.error(e)
                        })
                break
            case ('open'):
                getUrl({negotiation_uuid: props.negotiation, uuid: props.selectedDocument[0].key}).unwrap()
                    .then((response) => {
                            downloadFile(response.url)
                        }
                    )
                    .catch((e: any) => {
                        message.error("Impossibile aprire il file")
                        console.error("Open file", e)
                    })
                break
            case('rename'):
                setIsModalRenameVisible(true)
                break
            case('remove'):
                setIsModalRemoveVisible(true)
                break
            case('move'):
                setIsModalMoveVisible(true)
                break
            case('properties'):
                setIsModalPropertiesVisible(true)
                break
        }
    }

    const items = props.readOnly && props.selectedDocument.length === 1 ? [
            {
                label: <div style={{cursor: "default"}}>Selezione: {props.selectedDocument.length}</div>,
                key: 'nDoc',
                disabled: true
            },
            {
                label: 'Apri/Scarica',
                key: 'open',
                icon: <FileOutlined/>
            },
            {
                label: 'Proprietà',
                key: 'properties',
                icon: <InfoCircleOutlined/>
            }
        ] :
        props.selectedDocument.length !== 1 && props.readOnly ?
            [
                {
                    label: <div style={{cursor: "default"}}>Selezione: {props.selectedDocument.length}</div>,
                    key: 'nDoc',
                    disabled: true
                },
                {
                    label: 'Scarica',
                    key: 'open',
                    icon: <DownloadOutlined/>
                }
            ] : props.selectedDocument.length === 1 ? [
                    {
                        label: <div style={{cursor: "default"}}>Selezione: {props.selectedDocument.length}</div>,
                        key: 'nDoc',
                        disabled: true
                    },
                    {
                        label: 'Apri/Scarica',
                        key: 'open',
                        icon: <FileOutlined/>
                    },
                    {
                        label: 'Rinomina',
                        key: 'rename',
                        icon: <EditOutlined/>,
                    },
                    {
                        label: 'Sposta di cartella',
                        key: 'move',
                        icon: <FolderOutlined/>,
                    },
                    {
                        label: 'Elimina',
                        key: 'remove',
                        icon: <DeleteOutlined/>,
                    },
                    {
                        label: 'Proprietà',
                        key: 'properties',
                        icon: <InfoCircleOutlined/>
                    }
                ] :
                [
                    {
                        label: <div style={{cursor: "default"}}>Selezione: {props.selectedDocument.length}</div>,
                        key: 'nDoc',
                        disabled: true
                    },
                    {
                        label: 'Scarica',
                        key: 'download',
                        icon: <DownloadOutlined/>,
                    },
                    {
                        label: 'Sposta di cartella',
                        key: 'move',
                        icon: <FolderOutlined/>,
                    },
                    {
                        label: 'Elimina',
                        key: 'remove',
                        icon: <DeleteOutlined/>,
                    },
                ]


    return <><Menu
        onClick={handleMenuClick}
        items={items}
    />
        {props.selectedDocument && <RemoveDocumentModal document={props.selectedDocument} remove={props.handleDelete}
                                                        visible={isModalRemoveVisible}
                                                        close={() => setIsModalRemoveVisible(false)}/>}
        {props.selectedDocument.length === 1 &&
            <RenameDocumentModal document={props.selectedDocument[0]} onlyFilesMode={props.onlyFilesMode}
                                 rename={props.handleRename} visible={isModalRenameVisible}
                                 close={() => setIsModalRenameVisible(false)}/>}
        {props.selectedDocument &&
            <MoveDocumentModal document={props.selectedDocument} options={props.options} move={props.handleChangeDir}
                               visible={isModalMoveVisible} close={() => setIsModalMoveVisible(false)}/>}
        {props.selectedDocument.length === 1 &&
            <PropertiesDocumentModal document={props.selectedDocument[0]} options={props.options}
                                     onlyFiles={props.onlyFilesMode} visible={isModalPropertiesVisible}
                                     close={() => setIsModalPropertiesVisible(false)}/>}
    </>
}