import {message, Modal} from "antd";

type FileDownloaderHook = {
    downloadFile: (fileUrl: string) => void;
};

const isExcelContentType = (type: string | null): boolean => {
    return [
        "application/vnd.ms-excel",
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        "text/csv",
    ].includes(type || "");
};

const useFileDownloader = (): FileDownloaderHook => {
    const fetchContentType = async (fileUrl: string): Promise<string | null> => {
        try {
            const response = await fetch(fileUrl);
            return response.headers.get("Content-Type");
        } catch (error) {
            console.error("Errore nel recupero del Content-Type:", error);
            return null;
        }
    };

    const handleExcelDownload = (fileUrl: string) => {
        Modal.confirm({
            title: "Attenzione!",
            content: "Il file che stai per aprire potrebbe contenere macro o contenuti potenzialmente pericolosi.",
            onOk: () => {
                Modal.destroyAll();
                window.open(fileUrl);
            },
        });
    };

    const handleOtherDownload = (fileUrl: string) => {
        window.open(fileUrl);
    };

    const handleContentTypeCheckFailure = (fileUrl: string) => {
        Modal.confirm({
            title: "Attenzione!",
            content: "Impossibile verificare il tipo di file, proseguire con il download del file?",
            onOk: () => {
                Modal.destroyAll();
                window.open(fileUrl);
            },
        });
    };

    const downloadFile = async (fileUrl: string) => {
        try {
            const contentType = await fetchContentType(fileUrl);

            if (contentType !== null) {
                if (isExcelContentType(contentType)) {
                    handleExcelDownload(fileUrl);
                } else {
                    handleOtherDownload(fileUrl);
                }
            } else {
                handleContentTypeCheckFailure(fileUrl);
            }
        } catch (error) {
            message.error({
                content: "Si è verificato un errore durante il download del file.",
            });
        }
    };

    return {
        downloadFile,
    };
};

export default useFileDownloader;