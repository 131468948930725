import {CopyOutlined} from "@ant-design/icons";
import {Button, message, Select, Form, Space, notification, Col} from "antd";
import React, {useCallback, useEffect, useState} from "react";
import ConfirmCancelModal from "../../../../modals/ConfirmCancelModal";
import {
    useCloneNegotiationMutation
} from "../../../../../redux/api/negotiationsApiSlice";
import {NegotiationModel} from "../../../../../types/negotiations";
import {useSelector} from "react-redux";
import {selectOptions} from "../../../../../redux/features/optionsSlice";
import {useDuplicatedNegotiation} from "../../../../../context/DuplicatedNegotiationContext";

const DuplicateNegotiationModalButton = ({negotiation}: { negotiation: NegotiationModel }) => {

    const lobs = useSelector(selectOptions).lobs
    const [selectedLob, setSelectedLob] = useState(negotiation.lob.uuid)
    const duplicatedContext = useDuplicatedNegotiation();

    const [
        isModalOpen,
        setIsModalOpen
    ] = useState<boolean>(false);
    const [
        errorMessage,
        setErrorMessage] = useState<string>('');


    const [duplicate, {
        isLoading: isDuplicationInProgress,
    }] = useCloneNegotiationMutation();
    const isBtnDisabled = !!(isDuplicationInProgress)

    useEffect(() => {
        if (isModalOpen) {
            setErrorMessage('')
        }
    }, [isModalOpen])
    const lobOptions = lobs
        .filter(lob => lob.macro_lob === negotiation.lob.macro_lob)
        .map(el => ({
            label: el.name,
            value: el.uuid
        }))

    const handleDuplicate = useCallback(() => {
        duplicate({
            uuid: negotiation.uuid,
            lob: selectedLob
        })
            .unwrap()
            .then((response) => {
                if (duplicatedContext) {
                    const {setDuplication} = duplicatedContext
                    setDuplication({negotiationFrom: negotiation.uuid, negotiationTo: response.negotiation})
                }
                message.success('Trattativa duplicata con successo.');
                setIsModalOpen(false)
            })
            .catch((error) => {
                message.error("La duplicazione non è andata a buon fine.")
                if (error.data?.message)
                    setErrorMessage(error.data.message)
            })
    }, [duplicate, duplicatedContext, negotiation.uuid, selectedLob])


    return (
        <>
            <ConfirmCancelModal
                title={"Duplicazione trattativa"}
                open={isModalOpen}
                okText={'Duplica'}
                okButtonProps={{
                    disabled: isBtnDisabled,
                    loading: isDuplicationInProgress
                }}
                onCancel={() => setIsModalOpen(!isModalOpen)}
                onOk={handleDuplicate}
            >
                <Space direction={"vertical"}>
                    <p>Selezionare la lob della nuova trattativa:</p>
                    <Select
                        style={{width: '100%'}}
                        options={lobOptions}
                        value={selectedLob}
                        virtual={false}
                        onSelect={(v) => setSelectedLob(v)}
                    />

                    {errorMessage && <p style={{color: 'red'}}>Error: {errorMessage}</p>}
                </Space>
            </ConfirmCancelModal>
            <Button
                style={{width: '100%'}}
                type={'text'}
                icon={<CopyOutlined/>}
                onClick={() => setIsModalOpen(!isModalOpen)}
            />
        </>
    );
}

export default DuplicateNegotiationModalButton;