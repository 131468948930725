import {States} from "../../../types";
import {useNavigate} from "react-router-dom";
import {useCreateNegotiationStatusTransitionMutation,} from "../../../redux/api/negotiationsApiSlice";
import React, {Dispatch, SetStateAction, useCallback, useEffect, useState} from "react";
import {FormInstance, message, Modal} from "antd";
import {NegotiationFormModel, NegotiationModel, UpdateNegotiationRequest} from "../../../types/negotiations";
import {CompleteNegotiationStatusView, NegotiationStatusViewBaseProps} from "./status/CompleteNegotiationStatusView";
import {ContractorDataFormModel} from "../../../types/contractors";
import {DuplicatedNegotiationProvider} from "../../../context/DuplicatedNegotiationContext";

export type NegotiationDetailStatusPropsType = {
    negotiation: NegotiationModel,
    setNegotiation: (negotiation: NegotiationModel) => void
    updateNegotiation: (data: Partial<UpdateNegotiationRequest>) => Promise<NegotiationModel | undefined>
    form: FormInstance<NegotiationFormModel>,
    contractorDataForm: FormInstance<ContractorDataFormModel>,
    setIsFormDisabled: Dispatch<SetStateAction<boolean>>,
    goToDocuments: () => void
}

export function NegotiationDetailStatusManager({
                                                   negotiation,
                                                   setNegotiation,
                                                   updateNegotiation,
                                                   form,
                                                   contractorDataForm,
                                                   setIsFormDisabled,
                                                   goToDocuments,
                                               }: NegotiationDetailStatusPropsType) {

    const navigate = useNavigate();

    const [createStateTransition] = useCreateNegotiationStatusTransitionMutation();

    const [
        formFieldErrors,
        setFormFieldErrors
    ] = useState<{
        field: string | [string, number, string],
        error: string
    }[]>()
    const [
        isErrorStateTransitionModalOpen,
        setIsErrorStateTransitionModalOpen
    ] = useState<boolean>(false);
    const [
        isStateTransitionInProgress,
        setIsStateTransitionInProgress
    ] = useState<boolean>(false);
    const [
        uuidToGoTo,
        setUuidToGoTo
    ] = useState<string | null>(null);

    const resetErrors = useCallback((specificForm: FormInstance) => {
        specificForm
            .getFieldsError()
            .filter((el: any) => el.errors.length)
            .forEach((el: any) => {
                specificForm.setFields([
                    {
                        name: el.name,
                        errors: []
                    },
                ])
            })
    }, [])

    const resetWarnings = useCallback((form: FormInstance) => {
        form.getFieldsError()
            .filter((el: any) => el.warnings.length)
            .forEach((el: any) => {
                form.setFields([
                    {
                        name: el.name,
                        warnings: []
                    },
                ])
            })
    }, [])

    /*
    * newNegotiation viene usato da AssignNegotiationModal*/
    async function handleCreateStateTransition(data: {
        state_to: number,
        reason?: string,
        notes?: string
    }, newNegotiation?: NegotiationModel) {
        setIsStateTransitionInProgress(true);
        setIsFormDisabled(true);
        try {
            const createTransitionResult = await createStateTransition({id: negotiation.uuid, data: data}).unwrap();
            setUuidToGoTo(createTransitionResult.new_negotiation_uuid);
            setNegotiation({
                ...(newNegotiation || negotiation),
                is_portfolio_managed: createTransitionResult.is_portfolio_managed,
                state: createTransitionResult.state_to,
                available_state_transitions: createTransitionResult.available_states,
                revert_state_transition: createTransitionResult.revert_state_transition
            });
            if (createTransitionResult.state_from === States.Rore) {
                hideRoreSuggestions()
            }
            resetErrors(form);
            resetErrors(contractorDataForm);
            message.success('Stato aggiornato');
        } catch (e: any) {
            message.error('Impossibile aggiornare lo stato della trattativa');
            console.error('createStateTransition', e);
            if (Array.isArray(e.data)) {
                setIsErrorStateTransitionModalOpen(true);
                setFormFieldErrors(e.data);
            }
        } finally {
            setIsStateTransitionInProgress(false);
            setIsFormDisabled(false);
        }
    }

    async function updateNegotiationUnderwriter(underwriterId: string): Promise<NegotiationModel | undefined> {
        try {
            return await updateNegotiation({underwriter: underwriterId})
        } catch (e: any) {
            message.error('Impossibile assegnare l\'underwriter')
            console.error('updateUnderwriter')
        }
    }

    useEffect(() => { // set the focus on fields that have to be fixed to complete a state transition
        resetErrors(form)
        resetErrors(contractorDataForm)
        formFieldErrors?.forEach(el => {
            //CONTRACTOR FORM ERRORS
            if (el.field === 'province') {
                contractorDataForm.setFields([
                    {
                        name: el.field,
                        errors: [el.error]
                    },
                ])
            } else {
                //NEGOTIATION FORM ERRORS
                form.setFields([
                    {
                        name: el.field,
                        errors: [el.error]
                    },
                ])
            }
        })
    }, [contractorDataForm, form, formFieldErrors, resetErrors])

    const [isRoreSuggestionsVisible, setIsRoreSuggestionsVisible] = useState(false)

    const showRoreSuggestions = useCallback(() => {
        let roreSuggestions: { field: string, warning: string }[] | undefined = []
        if (negotiation.raw_data) {
            for (const [key, value] of Object.entries(negotiation.raw_data)) {
                roreSuggestions.push(({field: key, warning: `Dati trovati da RORE: ${value.join(', ')}`}))
            }
        }
        roreSuggestions.forEach(el => {
            form.setFields([
                {
                    name: el.field,
                    warnings: [el.warning]
                },
            ])
        })
        setIsRoreSuggestionsVisible(true)
    }, [form, negotiation.raw_data])

    const hideRoreSuggestions = useCallback(() => {
        resetWarnings(form)
        setIsRoreSuggestionsVisible(false)
    }, [form, resetWarnings])

    useEffect(() => {
        if (negotiation.state === States.Rore) {
            showRoreSuggestions()
        }
    }, [negotiation.state, showRoreSuggestions])

    const viewProps: NegotiationStatusViewBaseProps = {
        negotiation: negotiation,
        createStateTransition: handleCreateStateTransition,
        isStateTransitionInProgress: isStateTransitionInProgress,
        updateNegotiation: updateNegotiation,
        updateNegotiationUw: updateNegotiationUnderwriter,
        goToNewNegotiation: uuidToGoTo ? (() => {
            navigate(`/negotiations/${uuidToGoTo}`);
            setUuidToGoTo(null);
        }) : null,
        goToDocuments: goToDocuments,
        showRoreSuggestions,
        hideRoreSuggestions,
        isRoreSuggestionsVisible,
    }

    return (
        <>
            <DuplicatedNegotiationProvider>
                <CompleteNegotiationStatusView
                    {...viewProps}/>
            </DuplicatedNegotiationProvider>
            <Modal
                open={isErrorStateTransitionModalOpen}
                title={<p style={{color: "red"}}>Impossibile aggiornare lo stato della trattativa</p>}
                footer={null}
                onCancel={() => setIsErrorStateTransitionModalOpen(false)}>
                <p style={{paddingBottom: "100px"}}>
                    Per finalizzare il passaggio di stato risolvere i seguenti errori:</p>
                <ul>
                    {formFieldErrors?.map((el, index) => (
                        <li
                            key={index}
                            style={{
                                marginTop: "0.75em",
                                marginLeft: "0.85em"
                            }}>
                            {Array.isArray(el.error) ? el.error.join('_') : el.error}
                        </li>)
                    )}
                </ul>
                <p>
                    I campi interessati dall'errore sono evidenziati in rosso nel form e l'errore verrà rimosso una
                    volta
                    effettuato il passaggio di stato
                </p>
            </Modal>
        </>
    )
}