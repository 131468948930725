import {Col, Row, Card, Form, Radio, Select,Tooltip} from 'antd';
import { Typography} from 'antd';

import {CommonFormComponentProps} from "types/negotiations/components";
import {useSelector} from "react-redux";
import {selectOptions} from "redux/features/optionsSlice";
import {ProfileOutlined} from '@ant-design/icons';
import {AntCurrencyFormatInput} from "components/inputNumber";
import {DelegatedInsurerSelect} from "../selects";

const {Title} = Typography
const {Option} = Select

export default function Negotiation({
                                        forwaredRef,
                                        formInstance,
                                        saveData,
                                        onFormValuesChange,
                                        disabled,
                                        setCalculateField
                                    }: CommonFormComponentProps) {

    const {cancellationDays, lobs, policy_types} = useSelector(selectOptions)
    const watchIsAutoRenewal = Form.useWatch('is_auto_renewal', formInstance)
    const currentLobId = formInstance.getFieldValue('lob')
    const currentLob = lobs.find(lob => lob.uuid === currentLobId)
    const hasTiv = !!(currentLob?.can_manage_tiv)

    return (
        <Card ref={forwaredRef} id="negotiation" bordered={false} style={{
            width: '100%'
        }}>
            <Row style={{paddingBottom: '24px'}}>
                <Col>
                    <Title level={3}>
                        <ProfileOutlined/>
                    </Title>
                </Col>
                <Col style={{marginLeft: '12px'}}>
                    <Title level={3}>Dati trattativa</Title>
                </Col>
            </Row>

            <Form
                form={formInstance}
                disabled={disabled}
                name='negotiation'
                layout="vertical"
                requiredMark={false}
                onValuesChange={onFormValuesChange}
            >
                <DelegatedInsurerSelect disabled={disabled} fieldName='delegated_insurer' label='Delegataria'
                                        onCreation={(newDelegatedInsurer: string) => saveData({delegated_insurer: newDelegatedInsurer})}
                />
                <Form.Item label="Tipo polizza" name='policy_product_type'>
                    <Select
                        virtual={false}
                        showSearch
                        placeholder={disabled ? "" : "Seleziona il tipo polizza"}
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                            option?.children ? (option!.children as unknown as string).toLowerCase().includes(input.toLowerCase()) : false
                        }
                    >
                        {policy_types.map(policy => <Option key={policy.value}
                                                            value={policy.value}>{policy.text}</Option>)}
                    </Select>
                </Form.Item>
                <Form.Item label="Tacito rinnovo" name='is_auto_renewal'>
                    <Radio.Group>
                        <Radio value={true}>Si</Radio>
                        <Radio value={false}>No</Radio>
                    </Radio.Group>
                </Form.Item>
                <Row align='middle' justify='space-between'>
                    <Col style={{width: '100%'}}>
                        <Form.Item label="Termini di disdetta" name='cancellation_terms_days' hidden={!watchIsAutoRenewal}>
                            <Select
                                virtual={false}
                                showSearch
                                placeholder={disabled ? "" : "Seleziona il termine di disdetta"}
                                optionFilterProp="children"
                                // onSearch={onSearch}
                                filterOption={(input, option) =>
                                    option?.children ? (option!.children as unknown as string).toLowerCase().includes(input.toLowerCase()) : false
                                }
                            >
                                {cancellationDays.map(ct => <Option key={ct.value} value={ct.value}>{ct.text}</Option>)}
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
                <Row align='middle' justify='space-between'>
                    <Col style={{width: '48%'}}>
                        <Form.Item label="TIV" name='tiv' hidden={!hasTiv}>
                            <AntCurrencyFormatInput hasPlaceholder={!disabled}/>
                        </Form.Item>
                    </Col>
                    <Col style={{width: '48%'}}>
                        <Form.Item label="Somma assicurata / Limiti 100% (SL)" name='insured_sum' hidden={!hasTiv}>
                            <AntCurrencyFormatInput hasPlaceholder={!disabled}/>
                        </Form.Item>
                    </Col>
                </Row>
                <Form.Item label="Tipo premio" name='is_new_business'>
                    <Radio.Group>
                        <Radio value={true}>New business</Radio>
                        <Radio value={false}>Renewal</Radio>
                    </Radio.Group>
                </Form.Item>
                <Form.Item
                    shouldUpdate={(prevValues, curValues) => prevValues.is_calculated?.reference_premium !== curValues.is_calculated?.reference_premium}
                    style={{margin: 0, padding: 0}}>
                    {() => <Tooltip placement='top' title={formInstance.getFieldValue('is_calculated')?.reference_premium ? 'Premio imponibile 100%' : null} >
                        <Form.Item label="Premio di riferimento al 100%" name='reference_premium'>
                        <AntCurrencyFormatInput
                            hasPlaceholder={!disabled}
                            disabled={formInstance.getFieldValue('is_calculated')?.reference_premium}
                            calculate={() => setCalculateField('reference_premium')}/>
                    </Form.Item>
                    </Tooltip>}
                </Form.Item>
                <Form.Item label="Accessori" name='ancillary_charges'>
                    <AntCurrencyFormatInput hasPlaceholder={!disabled}/>
                </Form.Item>
                <Form.Item label="Commissioni di ristoro" name='refreshment_commissions' hidden={!(formInstance.getFieldValue('isa_share_perc') < 100)}>
                    <AntCurrencyFormatInput hasPlaceholder={!disabled} suffix={' %'}/>
                </Form.Item>
            </Form>
        </Card>

    )
}