import {Button, Col, Form, message, Modal, Row} from "antd";
import NegotiationLpsFormItems from "./NegotiationLpsFormItems";
import {PlusOutlined} from "@ant-design/icons";
import {useForm} from "antd/lib/form/Form";
import {useAddNegotiationLpsMutation} from "../../../../redux/api/negotiationLpsApiSlice";
import {useCallback, useState} from "react";
import {NegotiationLps} from "../../../../redux/api/negotiationLps";

export default function NegotiationLpsCreation({
                                                   hasTiv,
                                                   negotiationUuid,
                                                   excludedCountriesCodes
                                               }: {
    hasTiv?: boolean,
    negotiationUuid: string,
    excludedCountriesCodes?: string[]
}) {


    const [add, {isLoading}] = useAddNegotiationLpsMutation()
    const [addForm] = useForm()
    const {submit} = addForm
    const [isOpen, setIsOpen] = useState(false)
    const close = useCallback(() => {
        setIsOpen(false)
        addForm.resetFields()
    }, [addForm])
    const open = useCallback(() => setIsOpen(true), [])

    const handleFinish = useCallback(async (values: Pick<NegotiationLps, 'net_premium' | 'tiv'> & {
        country: { label: string, value: string }
    }) => {
        try {
            await add({
                negotiation: negotiationUuid,
                country: values.country.value,
                net_premium: values.net_premium ?? null,
                tiv: values.tiv ?? null,
            }).unwrap()
            message.success('Inserimento paese LPS avvenuto con successo')
            close()
        } catch {
            message.error("Errore nell'inserimento del paese LPS");
        } finally {

        }

    }, [add, close, negotiationUuid])

    return <>
        <Modal
            title={"Aggiungi paese LPS"}
            open={isOpen}
            closable={false}
            centered
            onCancel={close}
            footer={
                <Row align='middle' justify="space-between">
                    <Col>
                        <Button
                            disabled={isLoading}
                            onClick={close}
                            children={"Annulla"}
                        />
                    </Col>
                    <Col>
                        <Button
                            disabled={isLoading}
                            loading={isLoading}
                            onClick={submit}
                            type={"primary"}
                            children={"Aggiungi"}
                        />
                    </Col>
                </Row>
            }
        >
            <Form
                form={addForm}
                onFinish={handleFinish}
                layout={'vertical'}
            >
                <NegotiationLpsFormItems
                    excludedCountriesCodes={excludedCountriesCodes}
                    withTiv={hasTiv}
                />
            </Form>
        </Modal>
        <Button
            onClick={open}
            icon={<PlusOutlined/>}
            children={"Aggiungi"}
        />
    </>
}