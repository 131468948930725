import React, {useCallback, useEffect, useMemo, useState} from "react";
import {Button, Card, Col, Divider, Dropdown, Empty, message, Popover, Row, Space, Spin, Tree, TreeProps} from "antd";
import {FolderOpenOutlined, InfoCircleOutlined, LeftOutlined} from "@ant-design/icons";
import Title from "antd/lib/typography/Title";
import TitleRender from "../../components/documents/TitleRender";
import {useNavigate} from "react-router-dom";
import {CustomDataNode, GetDocumentsModel, TreeStructureModel} from "../../types/documents";
import {useLazyGetDocumentsQuery, useLazyGetDocumentUrlQuery, useLazyGetGeneralFoldersQuery} from "../../redux/api/documentsApiSlice";
import _, {cloneDeep} from "lodash";
import GeneralDropDownMenu from "../../components/documents/GeneralDropDownMenu";
import useFileDownloader from "../../hooks/useFileDownloader";

export default function GeneralDocuments() {

    const navigate = useNavigate()
    const [getFoldersStructure, {data: foldersStructureData}] = useLazyGetGeneralFoldersQuery()
    const [getDocuments, {isLoading: isDocumentLoading, isFetching: isDocumentFetching}] = useLazyGetDocumentsQuery()
    const [getUrl] = useLazyGetDocumentUrlQuery()
    const {downloadFile} = useFileDownloader()
    const [treeStructure, setTreeStructure] = useState<TreeStructureModel[]>()
    const [fileList, setFileList] = useState<TreeStructureModel[]>([])
    const [expandedKeys, setExpandedKeys] = useState<string[]>([])
    const [selectedKeys, setSelectedKeys] = useState<string[]>([])
    const [selectedDocument, setSelectedDocuments] = useState<CustomDataNode[]>([])

    useEffect(() => {
        getFoldersStructure({document_path: "2"}).unwrap()// 2-> documenti da processare
            .then((response)=> setTreeStructure(response))
            .catch((e: any) => {
                message.error('Errore nel caricamento dei file')
                console.error('fetchFoldersStructure', e)
            })
    }, [getFoldersStructure])

    const updateFileList = useCallback((newFiles: any) => {
        const treeFiles = newFiles.map((el: GetDocumentsModel) => {
            return {title: el.name, key: el.uuid, path: el.negotiation, selectable: true, isLeaf: true, canUpload: true, userCreation: el.user_creation, userEdit: el.user_edit, dateEdit: el.date_edit, dateCreation: el.date_creation}
        })
        const treeFilesID = newFiles.map((el: GetDocumentsModel) => {
            return el.uuid
        })
        setFileList((prevState)=> {
            let newFileList = cloneDeep(prevState)
            newFileList = newFileList.filter(el => !treeFilesID.includes(el.key))
            return [...newFileList, ...treeFiles]
        })
    }, [])

     const fetchDocumentsData = useCallback((negotiationId?: string) => {
         getDocuments({negotiation_id: negotiationId, path: "2"}).unwrap()
         .then((response) => updateFileList(response))
                .catch((e: any) => {
                    message.error('Impossibile caricare i file')
                    console.error('fetchDocumentsData', e)
                })
    }, [getDocuments, updateFileList])

    const composeTree = useCallback((tree: TreeStructureModel, leaves: TreeStructureModel[]) => {
        const treeCopy = _.cloneDeep(tree)
        for (let i = 0; i < treeCopy.children.length; i++)
            treeCopy.children[i] = composeTree(treeCopy.children[i], leaves)
        let filesToAppend = leaves.filter((el: TreeStructureModel) => el.path === treeCopy.key)
        treeCopy.children = [...treeCopy.children, ...filesToAppend]
        return treeCopy
    }, [])

    useEffect(() => {
        if (foldersStructureData)
            setTreeStructure(foldersStructureData.map(el=> composeTree(el, fileList)))
    }, [foldersStructureData, fileList, composeTree, expandedKeys, selectedKeys])

    const handleOnExpand = useCallback((expandedKeys: any, {expanded, node}: any) => {
        setSelectedKeys([])
        setSelectedDocuments([])
        setExpandedKeys((prevState) => [...expandedKeys])
        if (expanded && node.children.length === 0)
            for (let i in expandedKeys)
                fetchDocumentsData(expandedKeys[i] as string)
    }, [fetchDocumentsData])

    const handleDoubleClick = (event: any, node: any) => {
        if (node.isLeaf)
            getUrl({negotiation_uuid: node.path, uuid: node.key}).unwrap()
                .then((response) => {
                        downloadFile(response.url)
                    }
                )
                .catch((e: any) => {
                    message.error("Impossibile aprire il file")
                    console.error("Open file", e)
                })
    }

    const onSelect: TreeProps['onSelect'] = (keys: any[], info: any) => {
        let leafElements: CustomDataNode[] = []
        info.selectedNodes?.forEach((node: CustomDataNode) => {
            if (node.isLeaf)
                leafElements.push(node)
        })
        setSelectedDocuments(leafElements)
        setSelectedKeys([...keys])
    }

    const handleKeyDown = (event: any) => {
        if (event.key === 'Escape') {
            setSelectedKeys([])
            setSelectedDocuments([])
        }
    }

    const handleRightClick = (info: { event: any; node: any; }) => {
        if (!selectedKeys.includes(info.node.key) && info.node.isLeaf) {
            setSelectedDocuments([info.node])
            setSelectedKeys([info.node.key])
        }
    }

    const dropDownMenu = useMemo(() => <GeneralDropDownMenu selectedDocument={selectedDocument} folders={foldersStructureData}></GeneralDropDownMenu>, [selectedDocument])

    return <div tabIndex={-1} onKeyDown={handleKeyDown}>
        <Dropdown overlay={dropDownMenu} trigger={['contextMenu']} disabled={selectedDocument.length === 0}>
            <Row style={{padding: '1.5rem '}}>
                <Col span={24}>
                    <Card
                        style={{height: '88vh', overflow: "scroll"}}
                        title={<><Row justify={'space-between'} align={'middle'}>
                            <Col>
                                <Button type='text' icon={<LeftOutlined style={{color: '#1890FF'}}/>} onClick={() => navigate(`../negotiations/`)}>
                                    <span style={{color: '#1890FF'}}>Torna alle trattative</span>
                                </Button>
                            </Col>
                        </Row>
                            <Row justify={'space-between'} align={'middle'} wrap={true}>
                                <Col><Space><Title level={4}>Documenti</Title><InfoPopover/></Space></Col>
                                <Col>
                                    <p><FolderOpenOutlined/> Da lavorare</p>
                                </Col>
                            </Row></>}
                    >
                        {treeStructure?.length !== 0 && <Spin spinning={isDocumentLoading || isDocumentFetching} tip={"Caricamento documenti..."}>
                            <Col xs={0} sm={24} style={{marginLeft: "100px"}}><Row justify={"space-between"} align={"middle"} style={{padding: "0 4px 0 4px"}}>
                                <Col lg={12} sm={24}>Nome</Col>
                                <Col xl={4} xs={0}>Data aggiunta</Col>
                                <Col lg={4} xs={0}>Aggiunto da</Col>
                            </Row></Col>
                            <Col xs={0} sm={24}><Divider style={{margin: "5px 5px"}}/></Col>
                            <Tree.DirectoryTree
                                onDoubleClick={handleDoubleClick}
                                multiple={true}
                                treeData={treeStructure}
                                expandedKeys={expandedKeys}
                                expandAction={"click"}
                                onExpand={handleOnExpand}
                                onSelect={onSelect}
                                onRightClick={handleRightClick}
                                selectedKeys={selectedKeys}
                                titleRender={(n) => {
                                    return <TitleRender n={n}></TitleRender>
                                }}
                            ></Tree.DirectoryTree>
                        </Spin>}
                        {treeStructure?.length === 0 && <Row justify={"center"}><Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={"Nessun documento nella cartella selezionata"}></Empty></Row>}
                    </Card>
                </Col>
            </Row>
        </Dropdown>
    </div>
}

function InfoPopover () {
    const popoverText = useMemo(() => <div style={{paddingLeft: "0.625rem"}}>
            <p>In questa vista è possibile vedere i documenti suddivisi per trattativa</p>
    </div>, [])

    return <Popover
        placement={"leftTop"}
        title={<h3>Guida</h3>}
        trigger={"click"}
        content={popoverText}>
        <InfoCircleOutlined style={{fontSize: "18px", cursor: "pointer", color: "rgb(129, 138, 148)"}}/>
    </Popover>
}