import {Col, Form, message, Row, Tooltip} from "antd";
import {CountryFormItem, NetPremiumFormItem, TivFormItem} from "./NegotiationLpsFormItems";
import {useForm} from "antd/lib/form/Form";
import {
    useUpdateNegotiationLpsMutation
} from "../../../../redux/api/negotiationLpsApiSlice";
import React, {useCallback} from "react";
import {NegotiationLps} from "../../../../redux/api/negotiationLps";
import {debounce} from "lodash";
import NegotiationLpsDelete from "./NegotiationLpsDelete";
import {AntCurrencyFormatInput} from "../../../inputNumber";

export default function NegotiationLpsRow({

                                              hasTiv,
                                              negotiationUuid,
                                              initialValues,
                                              isaSharePerc,
                                              excludedCountriesCodes,
                                              disabled
                                          }: {
    hasTiv?: boolean,
    negotiationUuid: string,
    initialValues: NegotiationLps,
    isaSharePerc: number | null,
    excludedCountriesCodes?: string[],
    disabled?: boolean
}) {

    const [update, {isLoading}] = useUpdateNegotiationLpsMutation()
    const [updateForm] = useForm()

    const handleChange = useCallback(async (changedValues: any, values: Pick<NegotiationLps, 'net_premium' | 'tiv'> & {
        country: { label: string, value: string }
    }) => {
        try {
            await update({
                uuid: initialValues.uuid,
                negotiation: negotiationUuid,
                country: values.country.value,
                net_premium: typeof values.net_premium === 'number' ? values.net_premium : null,
                tiv: typeof values.tiv === 'number' ? values.tiv : null,
            }).unwrap()
            message.success('Aggiornamento paese LPS avvenuto con successo')
        } catch {
            message.error("Errore nell'aggiornamento del paese LPS");
            updateForm.resetFields()
        } finally {

        }

    }, [initialValues.uuid, negotiationUuid, update, updateForm])

    const debounceHandleChange = useCallback(debounce(handleChange, 1000), [])

    return <>
        <Form
            key={initialValues.uuid}
            initialValues={initialValues}
            form={updateForm}
            onValuesChange={debounceHandleChange}
            layout={'vertical'}
            disabled={disabled}
        >
            <Row align='bottom' justify='space-between'>
                <Col xs={{span: 24}} md={{span: 6}}>
                    <CountryFormItem excludedCountriesCodes={excludedCountriesCodes}/>
                </Col>
                <Col xs={{span: 24}} md={5}>
                    <NetPremiumFormItem/>
                </Col>
                {hasTiv && <Col xs={{span: 24}} md={5}>
                    <TivFormItem/>
                </Col>}
                <Col xs={{span: 24}} md={5}>
                    <Tooltip placement='top' title='Premio Imponibile al 100% * quota ISA'>
                        <Form.Item label={'Premio Imponibile in quota'}
                                   shouldUpdate={(prevValues, nextValues) => prevValues.net_premium !== nextValues.net_premium}>
                            {() => <AntCurrencyFormatInput
                                disabled
                                value={(isaSharePerc || 0) * (updateForm.getFieldValue('net_premium') || 0)}
                            />}
                        </Form.Item>
                    </Tooltip>
                </Col>
                {!disabled && <Col xs={{span: 24}} md={1}>
                    <Form.Item>
                        <NegotiationLpsDelete negotiationUuid={negotiationUuid} lpsUuid={initialValues.uuid}/>
                    </Form.Item>
                </Col>}
            </Row>


        </Form>
    </>
}