import React, { createContext, useState, useContext, PropsWithChildren } from 'react';

type Duplication = {negotiationFrom: string, negotiationTo: string}

// Definisci il tipo per il contesto
interface DuplicatedNegotiationContextType {
  duplication: Duplication | null;
  setDuplication: React.Dispatch<React.SetStateAction<Duplication | null>>;
}

// Crea il contesto con il tipo
const DuplicatedNegotiationContext = createContext<DuplicatedNegotiationContextType | undefined>(undefined);

// Provider del contesto
export function DuplicatedNegotiationProvider({ children }: PropsWithChildren) {
  const [duplication, setDuplication] = useState<Duplication | null>(null);

  return (
    <DuplicatedNegotiationContext.Provider value={{duplication, setDuplication}}>
      {children}
    </DuplicatedNegotiationContext.Provider>
  );
}

// Hook personalizzato per utilizzare il contesto
export function useDuplicatedNegotiation() {
  const context = useContext(DuplicatedNegotiationContext);
  if (!context) {
    throw new Error('useDuplicatedNegotiation deve essere utilizzato all\'interno di un DuplicatedNegotiationProvider');
  }
  return context;
}