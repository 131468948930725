import React, {useEffect, useState} from "react";
import {useLazyGetDocumentUrlQuery} from "../../redux/api/documentsApiSlice";
import {FileOutlined, InfoCircleOutlined, DownloadOutlined} from "@ant-design/icons";
import {Col, Menu, MenuProps, message, Modal, Row} from "antd";
import useFileDownloader from "../../hooks/useFileDownloader";

export default function GeneralDropDownMenu(props: any) {
    const [getUrl] = useLazyGetDocumentUrlQuery()
    const {downloadFile} = useFileDownloader()
    const [dateCreation, setDateCreation] = useState<string>("")
    const [dateEdit, setDateEdit] = useState<string>("")
    const [folderName, setFolderName] = useState<string>("")
    const [isModalPropertiesVisible, setIsModalPropertiesVisible] = useState<boolean>(false)

    useEffect(() => {
        if (props.selectedDocument[0]) {
            const folder = props.folders.find((el: any) => el.key === props.selectedDocument[0].path)
            if (folder)
                setFolderName(folder.title)
        }
    }, [props.folders, props.selectedDocument])

    const handleMenuClick: MenuProps['onClick'] = e => {
        switch (e.key) {
            case('download'):
                for (let i in props.selectedDocument)
                    getUrl({negotiation_uuid: props.negotiation, uuid: props.selectedDocument[i].key}).unwrap()
                        .then((response) => {
                            downloadFile(response.url)
                        })
                        .catch((e: any) => {
                            message.error("Impossibile scaricare il file " + props.selectedDocument[i].title)
                            console.error(e)
                        })
                break
            case ('open'):
                getUrl({negotiation_uuid: props.negotiation, uuid: props.selectedDocument[0].key}).unwrap()
                    .then((response) => {
                            downloadFile(response.url)
                        }
                    )
                    .catch((e: any) => {
                        message.error("Impossibile aprire il file")
                        console.error("Open file", e)
                    })
                break
            case('properties'):
                setIsModalPropertiesVisible(true)
                break
        }
    }

    const items = props.selectedDocument.length === 1 ? [
        {
            label: <div style={{cursor: "default"}}>Selezione: {props.selectedDocument.length}</div>,
            key: 'nDoc',
            disabled: true
        },
        {
            label: 'Apri/Scarica',
            key: 'open',
            icon: <FileOutlined/>
        },
        {
            label: 'Proprietà',
            key: 'properties',
            icon: <InfoCircleOutlined/>
        }
    ] : [
        {
            label: <div style={{cursor: "default"}}>Selezione: {props.selectedDocument.length}</div>,
            key: 'nDoc',
            disabled: true
        },
        {
            label: 'Scarica',
            key: 'download',
            icon: <DownloadOutlined/>
        }
    ]

    useEffect(() => {
        if (props.selectedDocument[0]) {
            const dateM = new Date(props.selectedDocument[0].dateEdit)
            const dateC = new Date(props.selectedDocument[0].dateCreation)
            setDateEdit(dateM.toLocaleString())
            setDateCreation(dateC.toLocaleString())
        }
    }, [props])

    return <>
        <Menu items={items} onClick={handleMenuClick}/>
        {props.selectedDocument.length === 1 &&
            <Modal open={isModalPropertiesVisible} onCancel={() => setIsModalPropertiesVisible(false)} footer={false}
                   title={"Proprietà documento"} width={700}>
                <Row justify={"space-around"} align={"middle"} style={{marginBottom: "1em"}}>
                    <Col span={10} style={{fontWeight: "bold"}}>Nome documento:</Col>
                    <Col span={10}>{props.selectedDocument[0].title}</Col>
                </Row>
                <Row justify={"space-around"} align={"middle"} style={{marginBottom: "1em"}}>
                    <Col span={10} style={{fontWeight: "bold"}}>Percorso:</Col>
                    <Col span={10}>{folderName}</Col>
                </Row>
                <Row justify={"space-around"} align={"middle"} style={{marginBottom: "1em"}}>
                    <Col span={10} style={{fontWeight: "bold"}}>Data aggiunta:</Col>
                    <Col span={10}>{dateCreation}</Col>
                </Row>
                <Row justify={"space-around"} align={"middle"} style={{marginBottom: "1em"}}>
                    <Col span={10} style={{fontWeight: "bold"}}>Aggiunto da:</Col>
                    <Col span={10}>{props.selectedDocument[0].userCreation}</Col>
                </Row>
                <Row justify={"space-around"} align={"middle"} style={{marginBottom: "1em"}}>
                    <Col span={10} style={{fontWeight: "bold"}}>Data ultima modifica:</Col>
                    <Col span={10}>{dateEdit}</Col>
                </Row>
                <Row justify={"space-around"} align={"middle"} style={{marginBottom: "1em"}}>
                    <Col span={10} style={{fontWeight: "bold"}}>Modificato da:</Col>
                    <Col span={10}>{props.selectedDocument[0].userEdit}</Col>
                </Row>
            </Modal>}
    </>
}