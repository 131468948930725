import {Button, Col, Divider, Input, message, Modal, Row, Space, Spin} from "antd";
import {FileOutlined, SearchOutlined} from "@ant-design/icons";
import {useLazyGetDocumentsQuery, useLazyGetDocumentUrlQuery} from "../../redux/api/documentsApiSlice";
import {Dispatch, SetStateAction, useEffect, useState} from "react";
import {CustomDataNode, GetDocumentsModel, TreeStructureModel} from "../../types/documents";
import useFileDownloader from "../../hooks/useFileDownloader";

export default function SearchDocumentModal({
                                                visible,
                                                close,
                                                negotiation,
                                                options,
                                                setExpandedKeys,
                                                setSelectedKeys,
                                                fetchDocument,
                                                selectedDocument
                                            }: { visible: boolean, close: () => void, negotiation: string | undefined, setExpandedKeys: Dispatch<SetStateAction<string[]>>, setSelectedKeys: Dispatch<SetStateAction<string[]>>, fetchDocument: (_path: string) => void, options: {label: string, value: string}[] | undefined, selectedDocument: Dispatch<SetStateAction<CustomDataNode[]>> }) {
    const [getDocuments, {isLoading: isDocLoading, isFetching: isDocFetching}] = useLazyGetDocumentsQuery()
    const [documents, setDocuments] = useState<GetDocumentsModel[]>([])
    const [inputValue, setInputValue] = useState<string>("")
    const [searchTerm, setSearchTerm] = useState<string | undefined>(undefined)
    const [searchDone, setSearchDone] = useState<boolean>(false)
    const [displayTerm, setDisplayTerm] = useState<string>("")

    const handleSearch = () => {
        if (searchTerm)
        {
            getDocuments({negotiation_id: negotiation, search: searchTerm}).unwrap()
                .then((response) => {
                    setDocuments([...response])
                    setDisplayTerm(searchTerm)
                    setSearchDone(true)
                    setSearchTerm(undefined)
                })
                .catch((e: any) => {
                    message.error("Impossibile effettuare la ricerca")
                    console.error(e)
                })
        }
    }

    const handleChange = (event: any) => {
        setInputValue(event.target.value)
        setSearchTerm(event.target.value.trim())
    }

    return <Modal
        open={visible}
        width={700}
        footer={false}
        title={"Cerca documenti"}
        destroyOnClose
        onCancel={() => {
            setDocuments([])
            setSearchDone(false)
            setInputValue("")
            close()
        }}
    >
        <Row justify={"space-around"}>
            <Col flex={1}>
                <Input placeholder={"Nome documento..."} value={inputValue} onChange={(event) => handleChange(event)} onPressEnter={handleSearch}></Input>
            </Col>
            <Col>
                <Button onClick={handleSearch}><SearchOutlined/>Cerca</Button>
            </Col>
        </Row>
        <Spin spinning={isDocFetching || isDocLoading} tip={"Caricamento documenti..."}>
            {documents.length !== 0 && <div><Row justify={"center"} style={{marginTop: "1rem"}}><h3>Documenti trovati con: " {displayTerm} "</h3></Row>
                {documents.map((el) => {
                    return <RowItem element={el} options={options} setExpandedKeys={setExpandedKeys} close={close} setSelectedKeys={setSelectedKeys}
                                    fetchDocument={fetchDocument} selectedDocument={selectedDocument} setDocument={setDocuments} setSearchDone={setSearchDone}
                                    setInputValue={setInputValue}/>
                })}</div>}
            {documents.length === 0 && searchDone && <div><Divider/><Row justify={"center"}><h4>Nessun file trovato con: " {displayTerm} "</h4></Row></div>}
        </Spin>
    </Modal>
}

function RowItem({
                     element,
                     options,
                     setExpandedKeys,
                     close,
                     setSelectedKeys,
                     fetchDocument,
                     selectedDocument,
                     setDocument,
                     setSearchDone,
                     setInputValue
                 }: { element: GetDocumentsModel, options: any, setExpandedKeys: Dispatch<SetStateAction<string[]>>, close: () => void, setSelectedKeys: Dispatch<SetStateAction<string[]>>, fetchDocument: (_path: string) => void, selectedDocument: Dispatch<SetStateAction<CustomDataNode[]>>, setDocument: Dispatch<SetStateAction<GetDocumentsModel[]>>, setSearchDone: Dispatch<SetStateAction<boolean>>, setInputValue: Dispatch<SetStateAction<string>> }) {
    const [getUrl] = useLazyGetDocumentUrlQuery()
    const [folderName, setFolderName] = useState<string>("")
    const {downloadFile} = useFileDownloader()

    useEffect(() => {
        const folder = options.find((el: any) => el.value === element.path)
        if (folder)
            setFolderName(folder.label)
    }, [element.path, options])

    return <div>
        <Divider/>
        <Row justify={"space-between"}>
            <Col flex={1}>
                <Space><FileOutlined style={{display: "flex", alignItems: "center"}}/> <p>{element.name}</p></Space>
                <p style={{color: "grey", fontSize: "12px"}}>{folderName}</p>
            </Col>
            <Col>
                <Button type={"link"} onClick={()=> {
                    getUrl({uuid: element.uuid, negotiation_uuid: element.negotiation}).unwrap()
                        .then((response) => {
                            downloadFile(response.url)
                        }
                    )
                    .catch((e: any) => {
                        message.error("Impossibile aprire il file")
                        console.error("Open file", e)
                    })
                }}>Apri</Button>
                <Button type={"link"} onClick={()=> {
                    setExpandedKeys([element.path, element.path.substring(0,1)])
                    setSelectedKeys([element.uuid])
                    let newSelectedDocument = {title: element.name, path: element.path, selectable: true, key: element.uuid, children: [], fileName: element.name, userCreation: element.user_creation, userEdit: element.user_edit, dateEdit: element.date_edit, dateCreation: element.date_creation}
                    selectedDocument([newSelectedDocument])
                    fetchDocument(element.path)
                    setDocument([])
                    setSearchDone(false)
                    setInputValue("")
                    close()
                }}>Naviga</Button>
            </Col>
        </Row>
    </div>
}