import Title from "antd/lib/typography/Title";
import {Col, Divider, Row} from "antd";
import NegotiationLpsCreation from "./NegotiationLpsCreation";
import {useGetNegotiationLpsQuery} from "../../../../redux/api/negotiationLpsApiSlice";
import NegotiationLpsRow from "./NegotiationLpsRow";

export default function NegotiationLps({hasTiv, negotiationUuid, isaSharePerc, disabled}: {
    hasTiv: boolean,
    negotiationUuid: string,
    isaSharePerc: number | null,
    disabled?: boolean
}) {

    const {data, isFetching, isUninitialized} = useGetNegotiationLpsQuery({negotiationUuid})
    const excludedCountries = data?.map(el => el.country.code) ?? []

    return <>
        <Divider/>
        <Row align='middle' justify='space-between' style={{marginBottom: '24px'}}>
            <Col>
                <Title level={5} style={{margin: 0}}>Paesi LPS</Title>
            </Col>
            {!disabled && <Col>
                <NegotiationLpsCreation
                    excludedCountriesCodes={excludedCountries}
                    negotiationUuid={negotiationUuid}
                    hasTiv={hasTiv}
                />
            </Col>}
        </Row>
        {data?.length === 0 &&
            <p style={{fontStyle: 'italic'}}>Nessun paese LPS presente. Per aggiungerlo cliccare sul pulsante
                'Aggiungi'</p>}
        {data?.map(el => <NegotiationLpsRow
            excludedCountriesCodes={excludedCountries}
            isaSharePerc={isaSharePerc}
            key={el.uuid}
            hasTiv={hasTiv}
            negotiationUuid={negotiationUuid}
            initialValues={el}
            disabled={disabled}
        />)}
        <Divider/>
    </>
}